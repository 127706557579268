<template>
  <div class="ui container">
    Instructors
  </div>
</template>

<script>

export default {
  name: 'Instructors',
  components: {}
}
</script>
